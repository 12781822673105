import React from 'react'
import { useController } from "react-hook-form"

import CustomTextInput from './CustomTextInput';


const CustomTextField = ({ label = "", placeholder = "", disabled = false, name = "", control, rules, type = "text", onBlur, onChange, inputClassName = "", isRequired, defaultValue }) => {

	const {
		field: inputProps,
		fieldState: { invalid, error },
	} = useController({
		name,
		control,
		rules,
		defaultValue,
	});


	return (
		<div className="w-full flex-col justify-start items-start gap-1 inline-flex">
			<div className="text-stone-700 flex flex-row text-sm md:text-base font-bold font-['Montserrat'] leading-none md:leading-[18px] tracking-tight">
				<div className="">{label} </div>
				{isRequired && <p className="text-red-600 ml-1">*</p>}
			</div>

			<CustomTextInput
				type={type}
				placeholder={placeholder}
				disabled={disabled}
				error={invalid}
				inputProps={inputProps}
				onChange={onChange}
				onBlur={onBlur}
				inputClassName={inputClassName}
			/>

			{invalid && (
				<div className="text-rose-700 text-xs md:text-sm font-light font-['Montserrat'] leading-[14px] md:leading-none">{error.message}</div>
			)}
		</div>
	);
}


export default CustomTextField;