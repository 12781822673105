import React from "react";


const CustomTextInput = ({ type = "text", placeholder = "", disabled = false, error = false, inputProps, onBlur = () => { }, onChange = () => { }, inputClassName = "" }) => {
	const filled = Boolean(inputProps.value);

	const classesInput = [
		"self-stretch px-4 md:px-5 py-2 md:py-[11px] bg-neutral-50 rounded-lg border justify-start items-center gap-2.5 inline-flex focus:shadow disabled:bg-gray-200",
		classes[error ? "error" : "primary"].input,
		filled && !error && !disabled ? "border-stone-700" : "",
	].join(" ");

	const classesPlaceholder = [
		"shrink basis-0 text-sm md:text-base font-normal font-['Montserrat'] leading-none md:leading-[18px]",
		classes[error ? "error" : "primary"].placeholder,
		filled && !error && !disabled ? "text-stone-700" : "",
	].join(" ");

	return (
		<input
			className={`${classesInput} ${classesPlaceholder} ${inputClassName}`}
			type={type}
			placeholder={placeholder}
			disabled={disabled}
			{...inputProps}
			value={inputProps.value || ""}
			onChange={(event) => {
				inputProps.onChange && inputProps.onChange(event);
				onChange(event);
			}}
			onBlur={(event) => {
				inputProps.onBlur && inputProps.onBlur(event);
				onBlur(event);
			}}
		/>
	);
};


const classes = {
	primary: {
		input: "border-stone-300 hover:bg-neutral-50 hover:border-neutral-400 focus:border-rose-800",
		placeholder: "text-stone-500 hover:text-neutral-700",
	},
	error: {
		input: "border-rose-700 hover:bg-rose-100 hover:border-rose-700 focus:border-rose-700",
		placeholder: "text-rose-700 hover:text-rose-700",
	},
};


export default CustomTextInput;