import React from "react";
import { Amplify, Auth } from "aws-amplify";
import moment from "moment";
import "moment/locale/es";

import awsExports from "./aws-exports";
import DigitalLicense from "./views/DigitalLicense";

Amplify.configure(awsExports);
Auth.configure(awsExports);
moment.locale("es");

const App = () => {
  return <DigitalLicense />;
};

export default App;
