import React from 'react'

import CustomLoading from './CustomLoading';


const CustomGlobalLoading = ({ isLoading = true }) => {

	if (!isLoading) {
		return null;
	}

	return (
		<>
			<div className="h-screen w-screen top-0 left-0 fixed z-40 bg-white opacity-70" />
			<div className="h-screen w-screen top-0 left-0 fixed z-50">
				<CustomLoading />;
			</div>
		</>
	);
}


export default CustomGlobalLoading;