const Label = ({ className, inline, textCenter, children }) => {
  const labelClasses = [
    "text-stone-700 font-['Montserrat'] uppercase",
    className,
    inline && "inline",
    textCenter && "text-center",
  ]
    .filter((x) => x)
    .join(" ");

  return <div className={labelClasses}>{children}</div>;
};

const NormalSM = (props = PROPS) => (
  <Label className="font-normal text-[6.04px] leading-[7.25px]" {...props} />
);

const NormalMD = (props = PROPS) => (
  <Label className="font-normal text-[5.18px] leading-[6.21px]" {...props} />
);

const NormalLG = (props = PROPS) => (
  <Label className="font-normal text-[6.31px] leading-[7.18px]" {...props} />
);

const NormalXL = (props = PROPS) => (
  <Label className="font-normal text-[8.06px] leading-[8.88px]" {...props} />
);

const Medium = (props = PROPS) => (
  <Label className="font-medium text-[8.63px] leading-[10.35px]" {...props} />
);

const BoldSM = (props = PROPS) => (
  <Label className="font-bold text-[6.04px] leading-[7.25px]" {...props} />
);

const BoldMD = (props = PROPS) => (
  <Label className="font-bold text-[8.50px] leading-[8.80px]" {...props} />
);

const BoldLG = (props = PROPS) => (
  <Label className="font-bold text-[9.90px] leading-[9.28px]" {...props} />
);

const BoldXL = (props = PROPS) => (
  <Label className="font-bold text-[9.13px] leading-[9.76px]" {...props} />
);

const BlackSM = (props = PROPS) => (
  <Label className="font-black text-[6.77px] leading-[8.12px]" {...props} />
);

const BlackMD = (props = PROPS) => (
  <Label className="font-black text-[7.77px] leading-[9.32px]" {...props} />
);

const BlackLG = (props = PROPS) => (
  <Label className="font-black text-[8.77px] leading-[10.32px]" {...props} />
);

const BlackXL = (props = PROPS) => (
  <Label className="font-black text-[9.63px] leading-[11.35px]" {...props} />
);

const PROPS = { inline: false, textCenter: false, children: undefined };

export {
  NormalSM,
  NormalMD,
  NormalLG,
  NormalXL,
  Medium,
  BoldSM,
  BoldMD,
  BoldLG,
  BoldXL,
  BlackSM,
  BlackMD,
  BlackLG,
  BlackXL,
};
