import React from 'react';


const CustomButton = ({ type = "filled", color = "primary", disabled = false, src, alt = "", onClick, submit = false, text = "", rounded = false, className = "" }) => {

	return (
		<button
			className={`h-8 md:h-10 pl-4 md:pl-6 pr-5 md:pr-7 py-2 md:py-2.5 ${rounded ? "rounded-[999px]" : "rounded-lg"} justify-center items-center gap-2 inline-flex ${classes[type][color]} disabled:opacity-50 ${className}`}
			disabled={disabled}
			onClick={onClick}
			type={submit ? "submit" : "button"}
		>
			{src && (
				<img className="w-4 md:w-5 h-4 md:h-5 relative" src={src} alt={alt} />
			)}
			<div className="text-sm font-medium font-['Montserrat'] leading-none tracking-tight">{text}</div>
		</button>
	);
};


const classes = {
	"filled": {
		primary: "bg-rose-800 hover:bg-rose-900 focus:bg-red-950 focus:shadow text-neutral-50",
		dark: "bg-neutral-600 hover:bg-neutral-700 focus:bg-stone-700 focus:shadow text-neutral-50",
		error: "bg-rose-700 hover:bg-rose-700 focus:bg-rose-700 focus:shadow text-neutral-50",
	},
	"outlined": {
		primary: "border border-rose-800 hover:bg-red-200 hover:border-rose-900 focus:bg-pink-400 focus:shadow focus:border-red-950 text-rose-800",
		dark: "border border-neutral-600 hover:bg-gray-200 hover:border-neutral-700 focus:bg-neutral-300 focus:shadow focus:border-stone-700 text-neutral-600",
		error: "border border-rose-700 hover:bg-rose-100 hover:border-rose-700 focus:bg-rose-100 focus:shadow focus:border-rose-700 text-rose-700",
	},
	"ghost": {
		primary: "hover:bg-red-200 focus:bg-pink-400 focus:shadow text-rose-800",
		dark: "hover:bg-gray-200 focus:bg-neutral-300 focus:shadow text-neutral-600",
		error: "hover:bg-rose-100 focus:bg-rose-100 focus:shadow text-rose-700",
	},
};


export default CustomButton;