import awsExports from "../aws-exports";

const DIGITAL_LICENSE_URL_MAP = {
  DEFAULT: "http://localhost:3000",
  dev: "https://d3tf3nfcefjvaq.cloudfront.net",
  testing: "https://d1hz8atcbdtztt.cloudfront.net",
  uat: "https://ddngjmgf25hvq.cloudfront.net",
  prod: "https://conductores.imoveqroo.com.mx",
};

export const cropImage = (src) => {
  return new Promise((resolve, reject) => {
    const sourceCanvas = document.createElement("canvas");
    const sourceCtx = sourceCanvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      sourceCanvas.width = img.width;
      sourceCanvas.height = img.height;

      sourceCtx.clearRect(0, 0, sourceCanvas.width, sourceCanvas.height);
      sourceCtx.drawImage(img, 0, 0);

      const imageData = sourceCtx.getImageData(
        0,
        0,
        sourceCanvas.width,
        sourceCanvas.height
      );
      const data = imageData.data;

      let minX = sourceCanvas.width;
      let minY = sourceCanvas.height;
      let maxX = -1;
      let maxY = -1;

      for (let y = 0; y < sourceCanvas.height; y++) {
        for (let x = 0; x < sourceCanvas.width; x++) {
          const alpha = data[(y * sourceCanvas.width + x) * 4 + 3];
          if (alpha > 0) {
            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
          }
        }
      }

      const width = maxX - minX + 1;
      const height = maxY - minY + 1;

      const croppedCanvas = document.createElement("canvas");
      const croppedCtx = croppedCanvas.getContext("2d");
      croppedCanvas.width = width;
      croppedCanvas.height = height;

      croppedCtx.drawImage(
        sourceCanvas,
        minX,
        minY,
        width,
        height,
        0,
        0,
        width,
        height
      );

      const base64String = croppedCanvas.toDataURL();
      resolve(base64String);
    };

    img.onerror = () => {
      reject(src);
    };

    img.src = src;
  });
};

export const getDigitalLicenseURL = (licenseId) => {
  const data = [licenseId];

  const env = awsExports.aws_user_files_s3_bucket.split("-")[1];
  let origin = DIGITAL_LICENSE_URL_MAP[env] || DIGITAL_LICENSE_URL_MAP.DEFAULT;

  const result = `${origin}?q=${encodeURIComponent(data.join(","))}`;

  return result;
};
