import React from 'react'
import ReactLoading from "react-loading";


const CustomLoading = ({ isLoading = true, children }) => {
	if (!isLoading) {
		return children;
	}

	return (
		<div className='h-full flex justify-center items-center'>
			<ReactLoading type='spin' color='#9f1239' />
		</div>
	);
}


export default CustomLoading;