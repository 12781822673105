import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { API } from "aws-amplify";
import { openDB } from "idb";

import LicenseViewer from "./LicenseViewer";
import CustomGlobalLoading from "../components/CustomGlobalLoading";
import CustomButton from "../components/CustomButton";
import container_background from "../assets/container_background.jpg";
import CustomTextField from "../components/CustomTextField";
import logo_quintana_roo from "../assets/logo_quintana_roo.svg";
import logo_mobility_institute from "../assets/logo_mobility_institute.png";
import { cropImage } from "../utils/Tools";

const DigitalLicense = () => {
  const [licenseDetails, setLicenseDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [invalid, setInvalid] = useState(null);
  const { control, handleSubmit } = useForm();

  const handleOnSubmit = (data) => {
    setIsLoading(true);

    getLicense(data).then((result) => {
      setLicenseDetails(result);
      setIsLoading(false);
      let entryDate = new Date();
      localStorage.setItem("entryDate", entryDate.toISOString());

      if (!result) {
        setInvalid("No se encontró ninguna licencia con estos datos");
      }
    });
  };

  const onCloseLicense = () => {
    deleteObjectStore(STORE_NAMES.queries);
    deleteObjectStore(STORE_NAMES.responses);
    setLicenseDetails(null);
  };

  useEffect(() => {
    const query = new URLSearchParams(document.location.search);
    const encodedValue = query.get("q");

    if (encodedValue) {
      handleOnSubmit({ encodedValue });
      return;
    }

    getObjectStore(STORE_NAMES.queries).then(
      (result) => result && handleOnSubmit(result)
    );
  }, []);

  if (licenseDetails) {
    return (
      <div
        className="h-screen flex justify-center items-center bg-repeat	bg-left-top bg-cover"
        style={{
          backgroundImage: `url(${container_background})`,
        }}
      >
        <LicenseViewer data={licenseDetails} onClose={onCloseLicense} />
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col">
      <div className="flex-1 flex justify-center items-center">
        <div className="flex flex-col items-center">
          <div className="w-full flex flex-col justify-center my-5 items-center">
            <label className="font-black text-stone-700 font-['Montserrat'] text-[16x] justify-center">
              LICENCIA DIGITAL
            </label>
          </div>
          <img
            src={logo_quintana_roo}
            alt="Icono Quintana Roo"
            width={148}
            height={148}
          />
          <img
            src={logo_mobility_institute}
            alt="Icono IMOVEQROO"
            width={170}
            height={148}
          />
        </div>
      </div>
      <div className="flex justify-center">
        <form
          className="flex-1 flex flex-col gap-14 bg-neutral-50 rounded-tl-2xl rounded-tr-2xl shadow px-8 pt-9 pb-10 max-w-sm"
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          <div className="flex flex-col gap-8">
            <CustomTextField
              name="cellPhone"
              label="Celular"
              control={control}
              onChange={() => setInvalid(null)}
              rules={{
                required: "Campo Obligatorio",
                pattern: {
                  value: /^[\d]+$/i,
                  message: "¡Sólo se admiten numeros!",
                },
              }}
            />
            <CustomTextField
              name="licenseNumber"
              label="Número de Licencia"
              control={control}
              onChange={() => setInvalid(null)}
              rules={{
                required: "Campo Obligatorio",
                pattern: {
                  value: /^[\d]+$/i,
                  message: "¡Sólo se admiten numeros!",
                },
              }}
            />
            {invalid && (
              <div className="text-rose-700 text-xs md:text-sm font-light font-['Montserrat'] leading-[14px] md:leading-none">
                {invalid}
              </div>
            )}
          </div>
          <div
            className="flex justify-center"
            style={{ paddingBottom: getNavigationBarHeight() }}
          >
            <CustomButton className="w-44" text="Buscar" submit />
          </div>
        </form>
      </div>
      <CustomGlobalLoading isLoading={isLoading} />
    </div>
  );
};

const putObjectStore = async (storeName, data) => {
  const previousValue = await getObjectStore(storeName);

  const query = {
    ...data,
    path: API_SETTINGS.path,
    timestamp:
      previousValue?.timestamp ?? new Date().getTime() + 8 * 60 * 60 * 1000,
  };
  const tx = db.transaction(storeName, "readwrite");
  const store = tx.objectStore(storeName);
  store.put(query);
  await tx.done;
};

const getObjectStore = async (storeName) => {
  const tx = db.transaction(storeName, "readonly");
  const store = tx.objectStore(storeName);
  const value = await store.get(API_SETTINGS.path);

  if (new Date().getTime() > value?.timestamp) {
    await deleteObjectStore(storeName);
    return null;
  }

  return value;
};

const deleteObjectStore = async (storeName) => {
  const tx = db.transaction(storeName, "readwrite");
  const store = tx.objectStore(storeName);
  store.delete(API_SETTINGS.path);
  await tx.done;
};

const getNavigationBarHeight = () => {
  const screenHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  const availableHeight = window.screen.height || window.innerHeight;
  const navigationBarHeight = screenHeight - availableHeight;
  return navigationBarHeight;
};

const getLicense = async ({ cellPhone, licenseNumber, encodedValue }) => {
  try {
    const params = {
      body: {
        cellPhone: cellPhone,
        licenseNumber: licenseNumber,
        encodedValue: encodedValue,
      },
    };

    const fetchData = async () => {
      try {
        const response = await API.post(
          API_SETTINGS.apiName,
          API_SETTINGS.path,
          params
        );
        const result = response.data.getLicencia;
        const [photography, signature] = await Promise.all([
          getFromSignedUrl(result.photography),
          getFromSignedUrl(result.signature),
        ]);
        result.photography = photography;
        const croppedImage = await cropImage(signature);
        result.signature = croppedImage;

        if (result) {
          putObjectStore(STORE_NAMES.queries, params.body);
          putObjectStore(STORE_NAMES.responses, result);
        }

        return result;
      } catch (error) {
        if (error.code !== "ERR_NETWORK") {
          console.error(error);
        }
      }
    };

    const cacheValue = await getObjectStore(STORE_NAMES.responses);
    const cacheQuery = await getObjectStore(STORE_NAMES.queries);

    if (
      cacheValue &&
      cacheQuery.cellPhone === cellPhone &&
      cacheQuery.licenseNumber === licenseNumber &&
      cacheQuery.encodedValue === encodedValue
    ) {
      fetchData();
      return cacheValue;
    } else {
      return fetchData();
    }
  } catch (error) {
    console.error(error);
  }
};

const getFromSignedUrl = async (url) => {
  if (!url) {
    return "";
  }

  try {
    const response = await fetch(url, {
      method: "GET",
    });

    if (response.ok) {
      return await response.text();
    }
  } catch (err) {
    console.error(err);
  }
};

const OPENDB_NAME = "request-cache-v2";

const STORE_NAMES = {
  queries: "queries",
  responses: "responses",
};

const API_SETTINGS = {
  apiName: "userRoles",
  path: "/getDigitalLicense",
};

const db = await openDB(OPENDB_NAME, 1, {
  upgrade: (db) => {
    db.createObjectStore(STORE_NAMES.queries, { keyPath: "path" });
    db.createObjectStore(STORE_NAMES.responses, { keyPath: "path" });
  },
});

export default DigitalLicense;
