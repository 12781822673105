/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://4hjkyjp4afesxj42t7gsdq5ala.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "public",
            "endpoint": "https://gvsj2b8ii0.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "userRoles",
            "endpoint": "https://xu0yt8dmj2.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:baf59cdc-4244-4ea4-a3f4-efda62c5e282",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_HIBrmv2Y1",
    "aws_user_pools_web_client_id": "5su4el95e5ns0l6f6iulvdq2st",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "prodconductoresfiles134154-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
